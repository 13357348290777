import icon from "../assets/game-icon.png";

export const questions = [
  {
    type: "TF",
    icon,
    password: "1234",
    titleAr: "اشياء متنوعة",
    titleEn: "Random Things",
    questions: [
      {
        id: 1,
        en: "Bananas are a type of citrus fruit",
        ar: `الموز هو نوع من الفواكه الحمضية.`,
        correctAns: false,
      },
      {
        id: 2,
        en: "Dolphins are fish",
        ar: `الدلافين هي أسماك.`,
        correctAns: false,
      },
      {
        id: 3,
        en: "The Earth is flat",
        ar: `الأرض مسطحة.`,
        correctAns: false,
      },
      {
        id: 4,
        en: "Mount Everest is the tallest mountain in the world",
        ar: `جبل إيفرست هو أعلى جبل في العالم.`,
        correctAns: true,
      },
      {
        id: 5,
        en: "Water boils at 100 degrees Celsius",
        ar: `يغلي الماء عند درجة حرارة 100 درجة مئوية.`,
        correctAns: true,
      },
      {
        id: 6,
        en: "The sun revolves around the Earth",
        ar: `الشمس تدور حول الأرض.`,
        correctAns: false,
      },
      {
        id: 7,
        en: "Spiders are insects",
        ar: `العناكب هي حشرات.`,
        correctAns: false,
      },
    ],
  },
  {
    type: "Select",
    icon,
    password: "1234",
    titleAr: "اشياء متنوعة",
    titleEn: "Random Things",
    questions: [
      {
        id: 1,
        en: "What is the capital city of Mars?",
        ar: `ما هي عاصمة كوكب المريخ؟`,
        choices: [
          { id: 1, en: "Redington", ar: "ريدنغتون", isCorrect: false },
          { id: 2, en: "Martopolis", ar: "مارتوبوليس", isCorrect: false },
          { id: 3, en: "Mars City", ar: "مدينة المريخ", isCorrect: true },
          { id: 4, en: "Solarburg", ar: "سولاربورج", isCorrect: false },
        ],
      },
      {
        id: 2,
        en: "What is the main ingredient in intergalactic pizza?",
        ar: `ما هو المكون الرئيسي في بيتزا مابين الكواكب؟`,
        choices: [
          { id: 1, en: "Stardust Cheese", ar: "جبنة الغبار النجمي", isCorrect: false },
          { id: 2, en: "Rocket Pepperoni", ar: "بيبروني الصاروخ", isCorrect: false },
          { id: 3, en: "Galactic Dough", ar: "عجينة المجرة", isCorrect: true },
          { id: 4, en: "Alien Anchovies", ar: "شرائح الرنجة الفضائية", isCorrect: false },
        ],
      },
      {
        id: 3,
        en: "Which planet is known for its ring system?",
        ar: `أي كوكب معروف بنظام حلقي لديه؟`,
        choices: [
          { id: 1, en: "Jupiter", ar: "المشتري", isCorrect: false },
          { id: 2, en: "Saturn", ar: "زحل", isCorrect: true },
          { id: 3, en: "Neptune", ar: "نبتون", isCorrect: false },
          { id: 4, en: "Mars", ar: "المريخ", isCorrect: false },
        ],
      },
      {
        id: 4,
        en: "How many moons does Earth have?",
        ar: `كم عدد الأقمار التي تمتلكها الأرض؟`,
        choices: [
          { id: 1, en: "One", ar: "واحدة", isCorrect: false },
          { id: 2, en: "Three", ar: "ثلاثة", isCorrect: false },
          { id: 3, en: "Five", ar: "خمسة", isCorrect: false },
          { id: 4, en: "One Moon", ar: "قمر واحد", isCorrect: true },
        ],
      },
      {
        id: 5,
        en: "What is the speed of light?",
        ar: `ما هي سرعة الضوء؟`,
        choices: [
          { id: 1, en: "299,792 kilometers per second", ar: "299,792 كيلومتر في الثانية", isCorrect: true },
          { id: 2, en: "150,000 miles per hour", ar: "150,000 ميل في الساعة", isCorrect: false },
          { id: 3, en: "500,000 meters per second", ar: "500,000 متر في الثانية", isCorrect: false },
          { id: 4, en: "Lightning speed", ar: "سرعة البرق", isCorrect: false },
        ],
      },
      {
        id: 6, en: "Which planet is known as the 'Red Planet'?",
        ar: `أي كوكب يعرف بـ 'الكوكب الأحمر'؟`,
        choices: [
          { id: 1, en: "Venus", ar: "الزهرة", isCorrect: false },
          { id: 2, en: "Mars", ar: "المريخ", isCorrect: true },
          { id: 3, en: "Jupiter", ar: "المشتري", isCorrect: false },
          { id: 4, en: "Mercury", ar: "عطارد", isCorrect: false },
        ],
      },
      {
        id: 7,
        en: "What is the largest planet in our solar system?",
        ar: `ما هو أكبر كوكب في نظامنا الشمسي؟`,
        choices: [
          { id: 1, en: "Neptune", ar: "نبتون", isCorrect: false },
          { id: 2, en: "Saturn", ar: "زحل", isCorrect: false },
          { id: 3, en: "Jupiter", ar: "المشتري", isCorrect: true },
          { id: 4, en: "Uranus", ar: "أورانوس", isCorrect: false },
        ],
      },
      {
        id: 8,
        en: "What is the smallest planet in our solar system?",
        ar: `ما هو أصغر كوكب في نظامنا الشمسي؟`,
        choices: [
          { id: 1, en: "Mercury", ar: "عطارد", isCorrect: true },
          { id: 2, en: "Venus", ar: "الزهرة", isCorrect: false },
          { id: 3, en: "Mars", ar: "المريخ", isCorrect: false },
          { id: 4, en: "Earth", ar: "الأرض", isCorrect: false },
        ],
      },
    ],
  },
  {
    type: "TF",
    icon,
    password: "1234",
    titleAr: "اشياء متنوعة",
    titleEn: "Random Things",
    questions: [
      {
        id: 1,
        en: "Bananas are a type of citrus fruit",
        ar: `الموز هو نوع من الفواكه الحمضية.`,
        correctAns: false,
      },
      {
        id: 2,
        en: "Dolphins are fish",
        ar: `الدلافين هي أسماك.`,
        correctAns: false,
      },
      {
        id: 3,
        en: "The Earth is flat",
        ar: `الأرض مسطحة.`,
        correctAns: false,
      },
      {
        id: 4,
        en: "Mount Everest is the tallest mountain in the world",
        ar: `جبل إيفرست هو أعلى جبل في العالم.`,
        correctAns: true,
      },
      {
        id: 5,
        en: "Water boils at 100 degrees Celsius",
        ar: `يغلي الماء عند درجة حرارة 100 درجة مئوية.`,
        correctAns: true,
      },
      {
        id: 6,
        en: "The sun revolves around the Earth",
        ar: `الشمس تدور حول الأرض.`,
        correctAns: false,
      },
      {
        id: 7,
        en: "Spiders are insects",
        ar: `العناكب هي حشرات.`,
        correctAns: false,
      },
    ],
  },
  {
    type: "Select",
    icon,
    password: "1234",
    titleAr: "اشياء متنوعة",
    titleEn: "Random Things",
    questions: [
      {
        id: 1,
        en: "What is the capital city of Mars?",
        ar: `ما هي عاصمة كوكب المريخ؟`,
        choices: [
          { id: 1, en: "Redington", ar: "ريدنغتون", isCorrect: false },
          { id: 2, en: "Martopolis", ar: "مارتوبوليس", isCorrect: false },
          { id: 3, en: "Mars City", ar: "مدينة المريخ", isCorrect: true },
          { id: 4, en: "Solarburg", ar: "سولاربورج", isCorrect: false },
        ],
      },
      {
        id: 2,
        en: "What is the main ingredient in intergalactic pizza?",
        ar: `ما هو المكون الرئيسي في بيتزا مابين الكواكب؟`,
        choices: [
          { id: 1, en: "Stardust Cheese", ar: "جبنة الغبار النجمي", isCorrect: false },
          { id: 2, en: "Rocket Pepperoni", ar: "بيبروني الصاروخ", isCorrect: false },
          { id: 3, en: "Galactic Dough", ar: "عجينة المجرة", isCorrect: true },
          { id: 4, en: "Alien Anchovies", ar: "شرائح الرنجة الفضائية", isCorrect: false },
        ],
      },
      {
        id: 3,
        en: "Which planet is known for its ring system?",
        ar: `أي كوكب معروف بنظام حلقي لديه؟`,
        choices: [
          { id: 1, en: "Jupiter", ar: "المشتري", isCorrect: false },
          { id: 2, en: "Saturn", ar: "زحل", isCorrect: true },
          { id: 3, en: "Neptune", ar: "نبتون", isCorrect: false },
          { id: 4, en: "Mars", ar: "المريخ", isCorrect: false },
        ],
      },
      {
        id: 4,
        en: "How many moons does Earth have?",
        ar: `كم عدد الأقمار التي تمتلكها الأرض؟`,
        choices: [
          { id: 1, en: "One", ar: "واحدة", isCorrect: false },
          { id: 2, en: "Three", ar: "ثلاثة", isCorrect: false },
          { id: 3, en: "Five", ar: "خمسة", isCorrect: false },
          { id: 4, en: "One Moon", ar: "قمر واحد", isCorrect: true },
        ],
      },
      {
        id: 5,
        en: "What is the speed of light?",
        ar: `ما هي سرعة الضوء؟`,
        choices: [
          { id: 1, en: "299,792 kilometers per second", ar: "299,792 كيلومتر في الثانية", isCorrect: true },
          { id: 2, en: "150,000 miles per hour", ar: "150,000 ميل في الساعة", isCorrect: false },
          { id: 3, en: "500,000 meters per second", ar: "500,000 متر في الثانية", isCorrect: false },
          { id: 4, en: "Lightning speed", ar: "سرعة البرق", isCorrect: false },
        ],
      },
      {
        id: 6, en: "Which planet is known as the 'Red Planet'?",
        ar: `أي كوكب يعرف بـ 'الكوكب الأحمر'؟`,
        choices: [
          { id: 1, en: "Venus", ar: "الزهرة", isCorrect: false },
          { id: 2, en: "Mars", ar: "المريخ", isCorrect: true },
          { id: 3, en: "Jupiter", ar: "المشتري", isCorrect: false },
          { id: 4, en: "Mercury", ar: "عطارد", isCorrect: false },
        ],
      },
      {
        id: 7,
        en: "What is the largest planet in our solar system?",
        ar: `ما هو أكبر كوكب في نظامنا الشمسي؟`,
        choices: [
          { id: 1, en: "Neptune", ar: "نبتون", isCorrect: false },
          { id: 2, en: "Saturn", ar: "زحل", isCorrect: false },
          { id: 3, en: "Jupiter", ar: "المشتري", isCorrect: true },
          { id: 4, en: "Uranus", ar: "أورانوس", isCorrect: false },
        ],
      },
      {
        id: 8,
        en: "What is the smallest planet in our solar system?",
        ar: `ما هو أصغر كوكب في نظامنا الشمسي؟`,
        choices: [
          { id: 1, en: "Mercury", ar: "عطارد", isCorrect: true },
          { id: 2, en: "Venus", ar: "الزهرة", isCorrect: false },
          { id: 3, en: "Mars", ar: "المريخ", isCorrect: false },
          { id: 4, en: "Earth", ar: "الأرض", isCorrect: false },
        ],
      },
    ],
  },
];
