import React, { useEffect, useState } from "react";
import styles from "./GameSplash.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useLanguageThemeContext } from "../LanguageThemeContext";
import { questions } from "../Appendices/questions";

const GameSplash = () => {
  const [data, setData] = useState({});
  const [category, setCategory] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const { language, lock1, lock2, lock3, lock4 } = useLanguageThemeContext();
  // const [randomQuizType, setRandomQuizType] = useState("");
  const [randomQuizType, setRandomQuizType] = useState("");

  useEffect(() => {
    setRandomQuizType(questions[parseInt(id) - 1].type);
  });

  useEffect(() => {
    if (id === "1") lock1 && navigate("/");
    else if (id === "2") lock2 && navigate("/");
    else if (id === "3") lock3 && navigate("/");
    else if (id === "4") lock4 && navigate("/");
    else navigate("/");
  }, [id]);

  useEffect(() => {
    switch (id) {
      case "1":
        setData({
          img: "",
          en: "Random questions about",
          ar: "أسئلة متنوعة في الحديقة الموسيقية",
          quiz: randomQuizType,
        });
        setCategory("CharactersImg");
        break;
      case "2":
        setData({
          img: "",
          en: "Random questions about",
          ar: "أسئلة متنوعة في الحديقة الموسيقية",
          quiz: randomQuizType,
        });
        setCategory("QudsImg");
        break;
      case "3":
        setData({
          img: "",
          en: "Random questions about",
          ar: "أسئلة متنوعة في الحديقة الموسيقية",
          quiz: randomQuizType,
        });
        setCategory("BattlesImg");
        break;
      case "4":
        setData({
          img: "",
          en: "Random questions about",
          ar: "أسئلة متنوعة في الحديقة الموسيقية",
          quiz: randomQuizType,
        });
        setCategory("PlacesImg");
        break;
      default:
        break;
    }
  }, [id, randomQuizType]);

  return (
    <div className="StackContainer Gradient">
      <div className="CloseBtn" onClick={() => navigate("/")} />
      <img src={questions[parseInt(id) - 1].icon} className={styles.CategoryIcon} />
      <div
        className={`${styles.InfoText} ${language === "ar" ? styles.rtl : styles.ltr
          }`}
      >
        {language === "ar" ? (
          <div>
            أسئلة متنوعة في
            <br />
            {questions[parseInt(id) - 1].titleAr}
          </div>
        ) : (
          <div>
            Question about the
            <br />
            {questions[parseInt(id) - 1].titleEn}
          </div>
        )}
      </div>

      <div className={styles.InfoWindow}>
        <div className={randomQuizType === "TF" ? styles.TF : styles.Select} />
        <div>
          {language === "ar" ? (
            <div>
              <br />
              {id === "2" ? "اختر الإجابة الصحيحة" : "حرّك البطاقات للإجابة"}
            </div>
          ) : (
            <div>
              <br />
              {id === "2"
                ? "Choose the correct answer"
                : "Swipe the cards to answer"}
            </div>
          )}
        </div>
      </div>

      <div
        onClick={() => navigate(`/game/${id}`, { state: { type: data.quiz } })}
        className={`ActionBtn ${styles.InfoBtnMargin}`}
      >
        {language === "ar" ? "ابدأ" : "Start"}
      </div>
    </div>
  );
};

export default GameSplash;
