import React, { useState, useEffect } from "react";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
// import Mode from "./Pages/Mode";
import Splash from "./Pages/Splash";
import Register from "./Pages/Register";
import SelectGame from "./Pages/SelectGame";
import GameSplash from "./Pages/GameSplash";
import Game from "./Pages/Game";
import { useLanguageThemeContext } from "./LanguageThemeContext";
import LeaderBoardPage from "./Pages/LeaderBoardPage";
import Results from "./Pages/Results";
import SharePage from "./Pages/SharePage";
import Loading from "./Components/Loading";
import SelectTeam from "./Pages/SelectTeam";

function App() {
  const { name } = useLanguageThemeContext();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (loaded)
    return (
      <>
        <Routes>
          {name === "" && (
            <>
              <Route path="*" element={<Navigate to="/" replace={true} />} />
              {/* <Route path="/" element={<SelectTeam />} /> */}
              <Route path="/" element={<Register />} />
            </>
          )}
          {name !== "" && (
            <>
              <Route path="*" element={<Navigate to="/" replace={true} />} />
              <Route path="/" element={<SelectGame />} />
              <Route path="/splash/:id" element={<GameSplash />} />
              <Route path="/game/:id" element={<Game />} />
              <Route path="/result" element={<Results />} />
              <Route path="/leaderboard" element={<LeaderBoardPage />} />
              <Route path="/share" element={<SharePage />} />
            </>
          )}
        </Routes>
      </>
    );
  else return <Loading />;
}

export default App;
