import React, { useEffect, useState } from "react";
import styles from "./SelectGame.module.css";
import Vector from "../assets/wide-logo (blue bg).png";
import { useLanguageThemeContext } from "../LanguageThemeContext";
import { useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";
import Password from "../Components/Password";
import Arrow from "../assets/Arrow.svg";
import { questions } from "../Appendices/questions";

const PASSWORDS = [
  { en: "1234", ar: "١٢٣٤" },
  { en: "1234", ar: "١٢٣٤" },
  { en: "1234", ar: "١٢٣٤" },
  { en: "1234", ar: "١٢٣٤" },
];

function SelectGame() {
  const {
    language,
    clear,
    lock1,
    setLock1,
    lock2,
    setLock2,
    lock3,
    setLock3,
    lock4,
    setLock4,
  } = useLanguageThemeContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) return <Loading />;
  else
    return (
      <div className="StackContainer">
        {showPassword === 1 && (
          <Password
            password={PASSWORDS[0]}
            onCorrect={() => {
              setLock1(false);
              setShowPassword(false);
              navigate("/splash/1");
            }}
            cancel={() => setShowPassword(0)}
          />
        )}
        {showPassword === 2 && (
          <Password
            password={PASSWORDS[1]}
            onCorrect={() => {
              setLock2(false);
              setShowPassword(false);
              navigate("/splash/2");
            }}
            cancel={() => setShowPassword(0)}
          />
        )}
        {showPassword === 3 && (
          <Password
            password={PASSWORDS[2]}
            onCorrect={() => {
              setLock3(false);
              setShowPassword(false);
              navigate("/splash/3");
            }}
            cancel={() => setShowPassword(0)}
          />
        )}
        {showPassword === 4 && (
          <Password
            password={PASSWORDS[3]}
            onCorrect={() => {
              setLock4(false);
              setShowPassword(false);
              navigate("/splash/4");
            }}
            cancel={() => setShowPassword(0)}
          />
        )}
        <div className="CloseBtn" onClick={clear} />
        <div
          className="LeaderBoardBtn"
          onClick={() => navigate("/leaderboard")}
        ></div>
        <div className={`StackContainer ${styles.HomeHeader}`}>
          <img src={Vector} className="CardToofanLogo" alt="Toofan Vector" />
        </div>

        <div className={styles.homeCard}>
          <div
            className={`${styles.HomeCategoriesRow1} ${language === "ar" && styles.rowRtl
              }`}
          >
            <div
              className={`StackContainer ${styles.Category}`}
              onClick={() =>
                lock1 ? setShowPassword(1) : navigate("/splash/1")
              }
            >
              <img
                className={`${styles.CategoryImage} ${lock1 && styles.Locked
                  }`}
                src={questions[0].icon}
              />
            </div>
            <img src={Arrow} alt="Arrow" className={language === "ar" ? styles.Arrow1 : styles.RArrow1} />
            <div
              className={`StackContainer ${styles.Category}`}
              onClick={() =>
                lock2 ? setShowPassword(2) : navigate("/splash/2")
              }
            >
              <img
                className={`${styles.CategoryImage} ${lock2 && styles.Locked
                  }`}
                src={questions[1].icon}
              />
            </div>
          </div>
          <img src={Arrow} alt="Arrow" className={language === "ar" ? styles.Arrow2 : styles.RArrow2} />
          <div
            className={`${styles.HomeCategoriesRow2} ${language === "ar" && styles.rowRtl
              }`}
          >
            <div
              className={`StackContainer ${styles.Category}`}
              onClick={() =>
                lock4 ? setShowPassword(4) : navigate("/splash/4")
              }
            >
              <img
                className={`${styles.CategoryImage} ${lock4 && styles.Locked
                  }`}
                src={questions[3].icon}
              />
            </div>
            <img src={Arrow} alt="Arrow" className={language === "ar" ? styles.Arrow1 : styles.RArrow1} />
            <div
              className={`StackContainer ${styles.Category}`}
              onClick={() =>
                lock3 ? setShowPassword(3) : navigate("/splash/3")
              }
            >
              <img
                className={`${styles.CategoryImage} ${lock3 && styles.Locked
                  }`}
                src={questions[2].icon}
              />
            </div>
          </div>
        </div>
      </div>
    );
}

export default SelectGame;