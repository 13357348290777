import React, { useState } from "react";
import "./Password.css";
import { useLanguageThemeContext } from "../LanguageThemeContext";

const Password = ({ password, onCorrect, cancel }) => {
  const { language } = useLanguageThemeContext();
  const [value, setValue] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const [isHiding, setIsHiding] = useState(false);

  const hide = () => {
    setIsHiding(true);
    setTimeout(() => cancel(), 300)
  }

  const submit = (e) => {
    e.preventDefault();
    if (value === password.en || value === password.ar) onCorrect();
    else setShowMsg(true);
  }

  return (
    <>
      <div className="password-background" onClick={hide} />
      <form onSubmit={submit} className={`password-popup ${isHiding && "hide-popup"}`}>
        <div>{language === "en" ? "Enter the Password" : "ادخل كلمة السر"}</div>
        <input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          required
          className={`password-input  ${language === "ar" && "rtl-password"}`}
        />
        <button
          type="submit"
          className="ActionBtn"
          style={{ marginTop: 20 }}
        >
          {language === "en" ? "Start" : "ابدأ"}
        </button>
      </form>
    </>
  );
};

export default Password;
