import React, { useEffect, useState } from "react";
import "./Results.css";
import Vector from "../assets/wide-logo (blue bg).png";
import Star from "../assets/Star.svg";
import { useLanguageThemeContext } from "../LanguageThemeContext";
import { useNavigate } from "react-router-dom";
import { getData } from "../fetch";
import Loading from "../Components/Loading";

function Results() {
  const [resultData, setResultData] = useState({ count: null, rank: null })
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const { language, name, score, latestScore, id } = useLanguageThemeContext();

  useEffect(() => {
    getData("teams").then((teams) => {
      const teamArray = Object.keys(teams).map((teamId) => ({
        id: teamId,
        ...teams[teamId],
      }));
      const sortedTeams = teamArray.sort((a, b) => b.score - a.score);
      const teamRankIndex = sortedTeams.findIndex((team) => team.id === id);
      setResultData({ count: teamArray.length, rank: teamRankIndex !== -1 ? teamRankIndex + 1 : "Not Found" })
    });
  }, [getData]);

  useEffect(() => {
    
    if (resultData.rank && resultData.count)
      setLoaded(true);

  }, [resultData]);

  if (loaded)
    return (
      <div className="StackContainer">
        <div className="CloseBtn" onClick={() => navigate("/")} />
        <div
          className="LeaderBoardBtn"
          onClick={() => navigate("/leaderboard")}
        />

        <div className="ResultHeader StackContainer">
          <img src={Vector} className="CardToofanLogo" alt="Toofan Vector" />
        </div>

        <div className="StackContainer">
          <div className="PlayerName">{name}</div>
          <div className="PlayerResult StackContainer">
            <div className="PlayerResultRow1">
              <img src={Star} className="ResultPageStar" alt="Star" />
              <div className="TotalPoints">{score}</div>
            </div>
            <div className="PlayerResultRow2">+ {latestScore}</div>
          </div>
          <hr className="ResultLine" />
          {language === "ar" ? (
            <div className="PlayerRank">
              ترتيبك <span className="PlayerRankBlue">{resultData.rank}</span> على{" "}
              <span className="PlayerRankBlue">{resultData.count}</span> لاعب
            </div>
          ) : (
            <div className="PlayerRank">
            You’re in place No. <span className="PlayerRankBlue">{resultData.rank}</span> out
              of <span className="PlayerRankBlue">{resultData.count}</span> players
            </div>
          )}
        </div>
      </div>
    );
  else return <Loading />;
}

export default Results;
