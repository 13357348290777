import React, { useState, useEffect, useRef } from "react";
import styles from "./Register.module.css";
import { useLanguageThemeContext } from "../LanguageThemeContext";
import { postData } from "../fetch";
import Vector from "../assets/wide-logo (blue bg).png";
import Loading from "../Components/Loading";

const Register = () => {
  const { language, setLanguage, setName, setId } = useLanguageThemeContext();
  const [data, setData] = useState({
    name: "",
    age: "",
  })
  const [loading, setLoading] = useState(false);

  const submit = (e) => {
    e.preventDefault();

    const timeRegistered = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Amman",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    postData("teams", {
      ...data,
      timeRegistered,
      score: 0,
    }).then((response) => {
      setName(data.name);
      setId(response.name);
    });
  };

  const selectRef = useRef(null);

  useEffect(() => {
    const handleFocus = (e) => {
      e.target.options[0].style.color = "red";
    };
    const handleBlur = (e) => {
      e.target.options[0].style.color = "initial";
    };

    const selects = selectRef.current.querySelectorAll("select");
    selects.forEach((select) => {
      select.addEventListener("focus", handleFocus);
      select.addEventListener("blur", handleBlur);
    });

    return () => {
      selects.forEach((select) => {
        select.removeEventListener("focus", handleFocus);
        select.removeEventListener("blur", handleBlur);
      });
    };
  }, []);

  if (!loading)
    return (
      <form
        ref={selectRef}
        className="StackContainer ImageGradient"
        onSubmit={submit}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <img src={Vector} className="ToofanLogo" alt="Cloud Conex" />
        <div className={styles.FormContainer}>
          <input
            required
            type="text"
            name="name"
            maxLength={18}
            className={`${language === "en" ? "ltr" : "rtl"} ${styles.FormInput
              }`}
            placeholder={language === "en" ? "Name" : "الاسم"}
            value={data.name}
            onChange={(e) => {
              const inputValue = e.target.value;
              setData({ ...data, name: e.target.value })
              if (inputValue.length < 2 || inputValue.length > 18) {
                e.target.setCustomValidity(language === "en" ? "Please enter a valid name" : "الرجاء إدخال اسم صالح");
              } else {
                e.target.setCustomValidity("");
              }
            }}
          />
          <input
            required
            type="number"
            name="age"
            maxLength={18}
            className={`${language === "en" ? "ltr" : "rtl"} ${styles.FormInput
              }`}
            placeholder={language === "en" ? "Age" : "العمر"}
            value={data.age}
            onChange={(e) => {
              const inputValue = e.target.value;
              setData({ ...data, age: e.target.value })
              if (inputValue.length < 2 || inputValue.length > 18) {
                e.target.setCustomValidity(language === "en" ? "Please enter a valid name" : "الرجاء إدخال اسم صالح");
              } else {
                e.target.setCustomValidity("");
              }
            }}
          />

          <button type="submit" className={`ActionBtn ${styles.FormBtnMargin}`}>
            {language === "en" ? "Register" : "تسجيل"}
          </button>
        </div>
        <div
          onClick={() => setLanguage(language === "en" ? "ar" : "en")}
          className={`LanguageActionBtn ${styles.FormBtnMargin}`}
        >
          {language === "en" ? "العربية" : "English"}
        </div>
      </form>
    );
  else return <Loading loaded={true} />;
};

export default Register;
