import React, { useState, useEffect } from "react";
import styles from "./TrueFalse.module.css";

const GameTimer = ({ onTick }) => {
  const [time, setTime] = useState(1);
  const [formattedTime, setFormattedTime] = useState("00:00");

  useEffect(() => {
    const timer = setInterval(() => {
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      const formattedMinutes = minutes.toString().padStart(2, "0");
      const formattedSeconds = seconds.toString().padStart(2, "0");
      setFormattedTime(`${formattedMinutes}:${formattedSeconds}`);
      onTick(time);
      setTime(time + 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [time, onTick]);

  return (
    <div className={styles.GameTimer}>
      <div className={styles.TimeText}>{formattedTime}</div>
    </div>
  );
};

export default GameTimer;
