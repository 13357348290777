import axios from "axios";

const baseUrl = "https://game-presentation-92793-default-rtdb.europe-west1.firebasedatabase.app/api";

// Function for making a GET request
const getData = async (endpoint) => {
  try {
    const response = await axios.get(`${baseUrl}/${endpoint}.json`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function for making a POST request
const postData = async (endpoint, data) => {
  try {
    const response = await axios.post(`${baseUrl}/${endpoint}.json`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function for making a PUT request
const putData = async (endpoint, data) => {
  try {
    const response = await axios.put(`${baseUrl}/${endpoint}.json`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export { getData, postData, putData };
