import React, { createContext, useContext, useEffect, useState } from "react";
import { putData } from "./fetch";

const LanguageThemeContext = createContext();

export function LanguageThemeContextProvider({ children }) {
  const [language, setLanguage] = useState("ar");
  const [theme, setTheme] = useState("");

  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [score, setScore] = useState(0);
  const [latestScore, setLatestScore] = useState(0);
  const [lock1, setLock1] = useState(null);
  const [lock2, setLock2] = useState(null);
  const [lock3, setLock3] = useState(null);
  const [lock4, setLock4] = useState(null);

  const version = 1.7;
  const clear = () => {
    setTheme("");
    setName("");
    setScore(0);
    setLatestScore(0);
    setLock1(false);
    setLock2(false);
    setLock3(false);
    setLock4(false);
    localStorage.clear();
    localStorage.setItem("version", version);
  };
  useEffect(() => {
    if (localStorage.getItem("version") !== `${version}`) {
      clear();
      localStorage.setItem("version", version);
    }
  }, []);

  const updateScore = (qScore = 0) => {
    setLatestScore(qScore);
    if (qScore > 0) {
      return new Promise((resolve) => {
        setScore((prevScore) => {
          const updatedScore = prevScore + qScore;
          putData(`teams/${id}/score`, updatedScore)
            .then(() => {
              resolve(updatedScore);
            })
            .catch((e) => {
              console.log(e.response.data.error);
            });
          return updatedScore;
        });
      });
    }
    return;
  };

  useEffect(() => {
    if (score > 0) {
      putData(`teams/${id}/score`, score);
    }
  }, [id, score]);

  // SET //
  useEffect(() => {
    if (name !== "") {
      localStorage.setItem("name", name);
    }
  }, [name]);
  useEffect(() => {
    if (id !== "") {
      localStorage.setItem("id", id);
    }
  }, [id]);
  useEffect(() => {
    if (score > 0)
      setTimeout(() => {
        localStorage.setItem("score", score);
      }, 500);
  }, [score]);
  useEffect(() => {
    if (latestScore > 0)
      setTimeout(() => {
        localStorage.setItem("latestScore", latestScore);
      }, 500);
  }, [latestScore]);
  // set locks
  useEffect(() => {
    if (lock1 === false) localStorage.setItem("lock1", lock1);
  }, [lock1]);
  useEffect(() => {
    if (lock2 === false) localStorage.setItem("lock2", lock2);
  }, [lock2]);
  useEffect(() => {
    if (lock3 === false) localStorage.setItem("lock3", lock3);
  }, [lock3]);
  useEffect(() => {
    if (lock4 === false) localStorage.setItem("lock4", lock4);
  }, [lock4]);

  // GET //
  useEffect(() => {
    if (localStorage.getItem("name")) {
      setName(localStorage.getItem("name"));
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem("id")) {
      setId(localStorage.getItem("id"));
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem("score")) {
      setScore(Number(localStorage.getItem("score")));
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem("latestScore")) {
      setLatestScore(Number(localStorage.getItem("latestScore")));
    }
  }, []);
  // get locks
  useEffect(() => {
    if (localStorage.getItem("lock1")) {
      setLock1(false);
    } else {
      setLock1(true);
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem("lock2")) {
      setLock2(false);
    } else {
      setLock2(true);
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem("lock3")) {
      setLock3(false);
    } else {
      setLock3(true);
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem("lock4")) {
      setLock4(false);
    } else {
      setLock4(true);
    }
  }, []);

  return (
    <LanguageThemeContext.Provider
      value={{
        language,
        setLanguage,
        theme,
        setTheme,
        name,
        setName,
        score,
        setScore,
        updateScore,
        latestScore,
        clear,
        setId,
        id,
        lock1,
        setLock1,
        lock2,
        setLock2,
        lock3,
        setLock3,
        lock4,
        setLock4,
      }}
    >
      {children}
    </LanguageThemeContext.Provider>
  );
}

export function useLanguageThemeContext() {
  return useContext(LanguageThemeContext);
}
