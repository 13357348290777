import React, { useEffect, useState } from "react";
import "./SelectOption.css";
import { useLanguageThemeContext } from "../LanguageThemeContext";
import { postData } from "../fetch";
import GameTimer from "./GameTimer";
import { useNavigate } from "react-router-dom";
import CardImg from "../assets/quizImage.png";

const SelectOption = ({ questions, id }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [popClass, setPopClass] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const navigate = useNavigate();
  const { language, name, updateScore } = useLanguageThemeContext();
  const [userAnswers, setUserAnswers] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [qScore, setQScore] = useState(0);
  const [endpoint, setEndpoint] = useState("");
  const [timeEntered, setTimeEntered] = useState("");
  const [time, setTime] = useState(0);

  useEffect(() => {
    setQScore(0);
  }, []);

  useEffect(() => {
    setTimeEntered(
      new Date().toLocaleString("en-US", {
        timeZone: "Asia/Amman",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
    );
  }, []);

  useEffect(() => {
    switch (id) {
      case "1":
        setEndpoint("responses/game-one/select");
        break;
      case "2":
        setEndpoint("responses/game-two/select");
        break;
      case "3":
        setEndpoint("responses/game-three/select");
        break;
      case "4":
        setEndpoint("responses/game-four/select");
        break;
      default:
        break;
    }
  }, [id]);

  const handleSelection = (question, choice) => {
    setSelectedOption(choice);
    if (choice.isCorrect) {
      setPopClass("TruePop animatePopup");
      setQScore(qScore + 40);
    } else {
      setPopClass("FalsePop animatePopup");
    }
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
      setCurrentIndex(currentIndex + 1);
      setSelectedOption(null);
    }, 1000);

    const timeAnswered = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Amman",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    setUserAnswers([
      ...userAnswers,
      {
        questionEn: question.en,
        questionAr: question.ar,
        answerEn: choice.en,
        answerAr: choice.ar,
        correctAnsEn: question.choices.filter((c) => c.isCorrect)[0].en,
        correctAnsAr: question.choices.filter((c) => c.isCorrect)[0].ar,
        timeAnswered,
      },
    ]);
  };

  const end = async () => {
    const timeEnded = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Amman",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    postData(endpoint, {
      name,
      userAnswers,
      timeEntered,
      timeEnded,
      score: qScore < 0 ? 0 : qScore,
    })
    await updateScore(qScore < 0 ? 0 : qScore);
    navigate("/result");
  };

  useEffect(() => {
    if (currentIndex === questions.length) {
      setQScore(qScore - time);
      end();
    }
  }, [currentIndex]);

  return (
    <div className="StackContainer">
      {showPopup && <div className={popClass} />}
      <GameTimer onTick={(t) => setTime(t)} />
      {currentIndex < questions.length && (
        <div className="QuizTwoCard StackContainer">
          <div className="QuestionTwoImg">
              <img src={CardImg} alt="img" className="cardImgPng" />
          </div>
          <div
            className={`QuestionTwoText ${language === "en" ? "ltr" : "rtl"}`}
          >
            {language === "en"
              ? questions[currentIndex].en
              : questions[currentIndex].ar}
          </div>
        </div>
      )}

      {currentIndex < questions.length && (
        <div className="GameTwoOptionsContainer">
          {questions[currentIndex].choices.map((choice) => (
            <div
              id="Option1"
              key={choice.id}
              onClick={() => handleSelection(questions[currentIndex], choice)}
              className={`GameTwoOption ${language === "en" ? "ltr-option" : "rtl-option"
                }
              ${showPopup && choice.isCorrect && "correct"}
              ${showPopup && !choice.isCorrect && selectedOption === choice && "incorrect"}
              `}
            >
              <div
                className={`OptionCircle ${language === "en" ? "ltr-circle" : "rtl-circle"
                  }`}
              >
                {choice.id}
              </div>
              <div
                className={`OptionText ${language === "en" ? "ltr-text" : "rtl-text"
                  }`}
              >
                {language === "en" ? choice.en : choice.ar}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectOption;
