import React, { useEffect, useState } from "react";
import "./LeaderBoardPage.css";
import "./Results.css";
import Vector from "../assets/wide-logo (blue bg).png";
import Search from "../assets/Search.svg";
import Star from "../assets/Star.svg";
import { useNavigate } from "react-router-dom";
import { getData } from "../fetch";
import Loading from "../Components/Loading";
import { useLanguageThemeContext } from "../LanguageThemeContext";

function LeaderBoardPage() {
  const navigate = useNavigate();
  const [ranks, setRanks] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { language } = useLanguageThemeContext();

  useEffect(() => {
    getData("teams").then((teams) => {
      const teamArray = Object.keys(teams).map((teamId) => ({
        id: teamId,
        ...teams[teamId],
      }));
      const sortedTeams = teamArray.sort((a, b) => b.score - a.score);
      setRanks(sortedTeams);
    });
  }, []);

  const filteredRanks = searchTerm
    ? ranks.filter((rank) =>
      rank.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : ranks;

  if (ranks.length > 0)
    return (
      <div className="StackContainer">
        <div className="CloseBtn" onClick={() => navigate("/")} />
        <div className="ResultHeader StackContainer">
          <img src={Vector} className="CardToofanLogo" alt="Toofan Vector" />
        </div>

        <div className="StackContainer">
          <div className="Search">
            <img
              className={language === "ar" ? "SearchIconRTL" : "SearchIconLTR"}
              src={Search}
              alt="SearchIcon"
            />
            <input
              className={language === "ar" ? "SearchInput rtl" : "SearchInput"}
              type="text"
              placeholder={language === "ar" ? "بحث ..." : "Search..."}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div
            className={
              language === "ar"
                ? "LeaderBoardHeaderRTL rtl"
                : "LeaderBoardHeaderLTR"
            }
          >
            <div>{language === "ar" ? "الفريق" : "Points"}</div>
            <div>{language === "ar" ? "النقاط" : "Team"}</div>
          </div>
          <div className="PlayersList">
            {filteredRanks.map((rank, index) => (
              <div className="PlayerListItemContainer" key={rank.id}>
                <div
                  className={
                    language === "ar"
                      ? "PlayerListItemRTL"
                      : "PlayerListItemLTR"
                  }
                >
                  <div
                    className={
                      language === "ar"
                        ? "PlayerItemScoreRTL"
                        : "PlayerItemScoreLTR"
                    }
                  >
                    <img src={Star} className="ScoreStar" alt="Star" />
                    <div
                      className={
                        language === "ar"
                          ? "PlayerScoreSideRTL"
                          : "PlayerScoreSideLTR"
                      }
                    >
                      <div className="PlayerItemScoreText">{rank.score}</div>
                      <div className="PlayerItemRankText">
                        {language === "ar" ? "#" + Number(index + 1) : Number(index + 1) + "#"}
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      language === "ar"
                        ? "PlayerNameSideRTL"
                        : "PlayerNameSideLTR"
                    }
                  >
                    <div className="PlayerItemName">{rank.name}</div>
                    {/* <div className="PlayerItemCountry">{rank.country}</div> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  else return <Loading loaded={true} />;
}

export default LeaderBoardPage;
