import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TrueFalse from "../Components/TrueFalse";
import SelectOption from "../Components/SelectOption";
import { questions } from "../Appendices/questions";
import Loading from "../Components/Loading";
import { useLanguageThemeContext } from "../LanguageThemeContext";

const Game = () => {
  const location = useLocation();
  const quiz = location.state?.type;
  const { id } = useParams();
  const [loaded, setLoaded] = useState(false);
  const { lock1, lock2, lock3, lock4 } = useLanguageThemeContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (id === "1") lock1 && navigate("/");
    else if (id === "2") lock2 && navigate("/");
    else if (id === "3") lock3 && navigate("/");
    else if (id === "4") lock4 && navigate("/");
    else navigate("/");
  }, [id]);

  const shuffleArray = (array) => {
    // Create a copy of the original array
    const shuffledArray = [...array];

    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }

    return shuffledArray;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loaded)
    return (
      <>
        <div className="CloseBtn" onClick={() => navigate("/")} />
        {quiz === "TF" ? (
          <TrueFalse id={id} questions={shuffleArray(questions[id - 1].questions)} />
        ) : (
          <SelectOption
            id={id}
            questions={shuffleArray(questions[id - 1].questions)}
          />
        )}
      </>
    );
  else return <Loading />;
};

export default Game;
